import oAxiosInstance from "config/Axios";

export const apiCallGetReportSalesRanking = (oData) => {
  return oAxiosInstance.post("reportes/rankingdeventas", oData);
};

export const apiCallGetReportPendingCredit = (oData) => {
  return oAxiosInstance.post("reportes/creditopendiente", oData);
};

export const apiCallGetReportStock = (oData) => {
  return oAxiosInstance.post("reportes/stock", oData);
};

export const apiCallGetReportPartnersBalance = (oData) => {
  return oAxiosInstance.post("reportes/mayordesaldos", oData);
};

export const apiCallGetReportTax = (oData) => {
  return oAxiosInstance.post("reportes/resumenimpuestos", oData);
};

export const apiCallGetReportUtilities = (oData) => {
  return oAxiosInstance.post("reportes/rentabilidad", oData);
};

export const apiCallGetReportLastPurchaseByClient = (oData) => {
  return oAxiosInstance.post("reportes/ultimaventa", oData);
};
