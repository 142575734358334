import React, { useState } from "react";
import oDayJs from "dayjs";
import { BiFile } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { DATES_FORMATS } from "config/constants/Dates";
import { BUTTON_CONFIG } from "config/constants/Button";
import { Button, ComboBox, DateInput, Table, Text } from "components";

import { actionReportGetSalesRanking, selectReportsState } from "../redux";
import { SalesRankingMapper } from "../mappers/ReportMapper";

const SalesRankingReport = () => {
  const oDispatch = useDispatch();
  const { aData } = useSelector(selectReportsState);

  const onGenerate = (oData) => oDispatch(actionReportGetSalesRanking(oData));

  const aTypes = [
    { label: "Cantidad", value: 1 },
    { label: "Ventas", value: 2 },
  ];

  const [oType, setType] = useState(aTypes[0]);
  const [dDateTo, setDateTo] = useState(oDayJs().toDate());
  const [dDateFrom, setDateFrom] = useState(oDayJs().subtract(1, "month").toDate());

  const onExport = () => {
    onGenerate({
      hasta: oDayJs(dDateTo).format(DATES_FORMATS.MYSQL_SERVER),
      desde: oDayJs(dDateFrom).format(DATES_FORMATS.MYSQL_SERVER),
      tipo: oType.value,
    });
  };

  return (
    <section>
      <section className="grid grid-cols-1 sm:grid-cols-2">
        <Text bold size="text-xl md:text-3xl" color="text-gray-800 dark:text-white" className="self-center text-center md:text-left">
          Ranking de Ventas
        </Text>
        <Button
          label="Generar reporte"
          onClick={onExport}
          type={BUTTON_CONFIG.CLASS.SUCCESS}
          icon={<BiFile className="text-white ml-2 text-lg" />}
          containerClassName="mt-5 mx-auto sm:mr-0 sm:mt-0 sm:ml-auto"
        />
      </section>
      <section className="grid grid-cols-1 sm:grid-cols-2 rounded-lg bg-white dark:bg-gray-800 py-4 px-5 mt-5 gap-5">
        <div className="relative col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Fecha inicio</span>
          </div>
          <DateInput
            value={dDateFrom}
            onChange={setDateFrom}
            placeholder="Fecha inicio"
            containerClassName="rounded-lg overflow-hidden ml-20"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400 text-sm">Fecha fin</span>
          </div>
          <DateInput
            value={dDateTo}
            onChange={setDateTo}
            placeholder="Fecha fin"
            containerClassName="rounded-lg overflow-hidden ml-20"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div className="relative col-span-1 gap-5">
          <ComboBox
            size="sm"
            selected={oType}
            options={aTypes}
            onChange={setType}
            emptyLabel="Ordenamiento"
          />
        </div>
      </section>
      <section className="py-5">
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                #
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                Articulo
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                Código
              </Table.Col>
              <Table.Col scope="col" className={`px-6 py-3 text-right ${oType.value === 1 ? "hidden md:table-cell" : ""}`}>
                Ventas
              </Table.Col>
              <Table.Col scope="col" className={`px-6 py-3 text-right ${oType.value === 2 ? "hidden md:table-cell" : ""}`}>
                Cantidad
              </Table.Col>
              <Table.Col />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aData.length > 0 ? (
              aData.map(SalesRankingMapper).map((oItem, nIndex) => (
                <Table.Row key={oItem.id}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {nIndex + 1}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oItem.article.name}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.article.code}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-right">
                    $ {oItem.sales}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-right">
                    {oItem.count}
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={5}>
                  <em>Sin resultados</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </section>
    </section>
  );
};

export default SalesRankingReport;
