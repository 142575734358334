export const SalesRankingMapper = aItems => {
  const mapper = oServerItem => ({
    article: {
      code: oServerItem.artCod || oServerItem.artCode,
      name: oServerItem.artNom,
    },
    count: oServerItem.cantidad,
    sales: oServerItem.vendido,
  });
  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const PendingCreditMapper = aItems => {
  const mapDiscounts = aDiscounts => {
    const mapper = oServerItem => ({
      type: oServerItem.tipo,
      nro: oServerItem.nro,
      cae: oServerItem.cae,
      amount: oServerItem.importe,
    });
    if (Array.isArray(aDiscounts)) return aDiscounts.map(mapper);
    if ((typeof aDiscounts).toString() === "object") return mapper(aDiscounts);
    return null;
  };

  const mapper = oServerItem => ({
    partner: {
      name: oServerItem.socNom,
    },
    document: {
      type: oServerItem.tipoDocNom,
      date: oServerItem.docFch,
      nro: oServerItem.docNro,
      cae: oServerItem.caeNro,
      total: oServerItem.docTotal,
      balance: oServerItem.saldo,
    },
    discounts: mapDiscounts(oServerItem.descuentos),
  });
  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const StockMapper = aItems => {
  const mapper = oServerItem => ({
    partner: {
      name: oServerItem.socNom,
    },
    family: {
      name: oServerItem.famNom,
    },
    article: {
      name: oServerItem.artNom,
      code: oServerItem.artCod,
    },
    minStock: oServerItem.stkMin,
    stock: oServerItem.stock,
    missing: oServerItem.faltante,
  });
  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const PartnerBalanceMapper = aItems => {
  const mapper = oServerItem => ({
    partner: {
      code: oServerItem.socCod,
      name: oServerItem.socNom,
    },
    balance: {
      usd: oServerItem.saldoUSD,
      uyu: oServerItem.saldoUYU,
    },
  });
  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const TaxMapper = aItems => {
  const mapper = oServerItem => ({
    type: oServerItem.tipoDocNom,
    date: oServerItem.docFch,
    nro: oServerItem.docNro,
    cae: oServerItem.caeNro,
    iva: oServerItem.totIva,
    status: oServerItem.estado,
  });
  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const UtilitiesMapper = aItems => {
  const mapper = oServerItem => ({
    code: oServerItem.artCod,
    name: oServerItem.artNom,
    totalCosts: oServerItem.costos ?? 0,
    totalSales: oServerItem.ventas ?? 0,
    utility: oServerItem.utilidad ?? 0,
    percentage: oServerItem.porcentaje,
  });
  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};

export const LastPurchaseMapper = aItems => {
  const mapper = oServerItem => ({
    partner: {
      code: oServerItem.socCod,
      name: oServerItem.socNom,
    },
    date: oServerItem.ultimaFecha,
    currency: oServerItem.ultimaMoneda,
    total: oServerItem.ultimoTotal,
    documentType: oServerItem.ultimoTipoDoc,
  });
  if (Array.isArray(aItems)) return aItems.map(mapper);
  if ((typeof aItems).toString() === "object") return mapper(aItems);
  return null;
};
