import { configureStore } from "@reduxjs/toolkit";

import SharedSlice from "redux/shared/SharedSlice";
import AuthSlice from "redux/auth/AuthSlice";
import { UsersSlice } from "pages/users";
import { TasksSlice } from "pages/tasks";
import { FamiliesSlice } from "pages/families";
import { LocationsSlice } from "pages/locations";
import { PricesSlice } from "pages/prices";
import { RolesSlice } from "pages/roles";
import { PartnersSlice } from "pages/partners";
import { PartnersGroupSlice } from "pages/partners/groups";
import { ArticlesSlice } from "pages/articles";
import { IVAsSlice } from "pages/iva";
import { DocumentTypesSlice } from "pages/documentTypes";
import { IndicatorsSlice } from "pages/indicators";
import { UnitsSlice } from "pages/units";
import { DocumentsSlice } from "pages/documents";
import { DocumentTypesPartnersSlice } from "pages/documentTypesPartners";
import { ReceiptsSlice } from "pages/receipts";
import { LotsSlice } from "pages/lots";
import { CashRegisterSlice } from "pages/cash_register";
import { ProductionSlice } from "pages/productions";
import { ReportsSlice } from "pages/reports";

const oStore = configureStore({
  reducer: {
    SharedSlice,
    AuthSlice,
    UsersSlice,
    TasksSlice,
    FamiliesSlice,
    LocationsSlice,
    PricesSlice,
    RolesSlice,
    PartnersSlice,
    ArticlesSlice,
    IVAsSlice,
    DocumentTypesSlice,
    IndicatorsSlice,
    UnitsSlice,
    DocumentsSlice,
    DocumentTypesPartnersSlice,
    ReceiptsSlice,
    LotsSlice,
    CashRegisterSlice,
    ProductionSlice,
    ReportsSlice,
    PartnersGroupSlice,
  },
});

export default oStore;
