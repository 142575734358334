import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FaSave, FaTrashAlt } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { BiEnvelope } from "react-icons/bi";
import printJS from "print-js";

import { Button, Checkbox, ComboBox, ConfirmationModal, Input, PartnerSuggestions, ScreenContainer, Table, Text } from "components";

import { actionMessage } from "redux/shared/SharedSlice";
import { actionPricesGetAll, selectAllPrices } from "pages/prices";
import { actionLocationsGetAll, selectAllLocations } from "pages/locations";
import { actionPartnersGroupGetAll, selectPartnersGroupState } from "./groups";
import { actionDocumentTypesPartnersGetAll, selectDocumentTypesPartners } from "pages/documentTypesPartners";
import { actionCleanPartner, actionPartnersCleanReport, actionPartnersCleanStatementAccount, actionPartnersDelete, actionPartnersFind, actionPartnersGetCreditorData, actionPartnersGetDebtorData, actionPartnersGetStatementAccount, actionPartnersGetStatementAccountReport, actionPartnersSendStatementAccountReport, actionPartnersUpdate, selectCreditorPartners, selectDebtorPartners, selectPartnerPartners, selectPartnerReport, selectStatementAccountPartners, selectStatusPartners } from "./redux";

import { CastDate, SubtractDate } from "utils/SharedUtils";
import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import PartnersLang from "./lang";
import { CURRENCY_CODES } from "config/constants/Currency";
import { PARTNERS_BALANCE_TYPES } from "./constants/Partners";
import CustomModal from "components/CustomModal";
import { DATES_FORMATS } from "config/constants/Dates";

const PartnersUpdate = () => {
  const oDispatch = useDispatch();
  const oNavigator = useNavigate();

  const { id: nId } = useParams();
  const aPrices = useSelector(selectAllPrices);
  const aLocations = useSelector(selectAllLocations);
  const oPartner = useSelector(selectPartnerPartners);
  const sReport = useSelector(selectPartnerReport);
  const bStatus = useSelector(selectStatusPartners);
  const oDebtorData = useSelector(selectDebtorPartners);
  const { aGroups } = useSelector(selectPartnersGroupState);
  const oCreditorData = useSelector(selectCreditorPartners);
  const oDataStatementAccount = useSelector(selectStatementAccountPartners);
  const aDocumentTypesPartners = useSelector(selectDocumentTypesPartners);

  const [sCode, setCode] = useState("");
  const [sName, setName] = useState("");
  const [bIsActive, setIsActive] = useState(true);
  const [sBusinessName, setBusinessName] = useState("");
  const [sRut, setRut] = useState("");
  const [sEmail, setEmail] = useState("");
  const [sPhone1, setPhone1] = useState("");
  const [sPhone2, setPhone2] = useState("");
  const [sDocument, setDocument] = useState("");
  const [sUrl, setUrl] = useState("");
  const [sAddress, setAddress] = useState("");
  const [sZip, setZip] = useState("");
  const [sDiscount, setDiscount] = useState("0");
  const [sObservations, setObservations] = useState("");
  const [bIsClient, setIsClient] = useState(false);
  const [bIsProvider, setIsProvider] = useState(false);
  const [bIsTaxed, setIsTaxed] = useState(false);

  const [sCreditBalance, setCreditBalance] = useState("");
  const [sCreditBalanceUsd, setCreditBalanceUsd] = useState("");
  const [sDueBalance, setDueBalance] = useState("");
  const [sDueBalanceUsd, setDueBalanceUsd] = useState("");

  const [bIsIncludeBranchesDebtor, setIsIncludeBranchesDebtor] = useState(false);
  const [bIsIncludeBranchesCreditor, setIsIncludeBranchesCreditor] = useState(false);
  const [nStatementType, setStatementType] = useState(null);
  const [nStatementCurrency, setStatementCurrency] = useState(CURRENCY_CODES.URUGUAYAN_PESO);
  const getStatementData = useMemo(() => nStatementCurrency === CURRENCY_CODES.URUGUAYAN_PESO ? oDataStatementAccount?.uyu : oDataStatementAccount?.dollar, [oDataStatementAccount, nStatementCurrency]);

  const [oGroup, setGroup] = useState(null);
  const [oPrice, setPrice] = useState(null);
  const [oFather, setFather] = useState(null);
  const [oLocation, setLocation] = useState(null);
  const [oDocumentTypePartner, setDocumentTypePartner] = useState(null);

  const [bDelete, setDelete] = useState(false);
  const [bSend, setSend] = useState(false);

  const groups = useMemo(() => {
    if (!aGroups) return [];
    let aGroupsCopy = JSON.parse(JSON.stringify(aGroups));
    return aGroupsCopy?.sort((itemA, itemB) => {
      let nameA = itemA.name.toUpperCase();
      let nameB = itemB.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }) || [];
  }, [aGroups]);

  useEffect(() => {
    if (!aLocations || aLocations.length === 0) oDispatch(actionLocationsGetAll());
    if (!aPrices || aPrices.length === 0) oDispatch(actionPricesGetAll());
    if (!aDocumentTypesPartners || aDocumentTypesPartners.length === 0) oDispatch(actionDocumentTypesPartnersGetAll());
    if (!aGroups || aGroups.length === 0) oDispatch(actionPartnersGroupGetAll());
    if (nId) oDispatch(actionPartnersFind(nId));
    return () => {
      oDispatch(actionCleanPartner());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bStatus) oNavigator("/partners");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bStatus]);

  useEffect(() => {
    if (oPartner) {
      setCode(oPartner.code);
      setName(oPartner.name);
      setIsActive(oPartner.isActive);
      setBusinessName(oPartner.businessName);
      setRut(oPartner.rut);
      setEmail(oPartner.email);
      setPhone1(oPartner.phone1);
      setPhone2(oPartner.phone2);
      setDocument(oPartner.document);
      setUrl(oPartner.url);
      setAddress(oPartner.address);
      setZip(oPartner.zip);
      setObservations(oPartner.observations);
      setIsClient(oPartner.isClient);
      setIsProvider(oPartner.isProvider);
      setIsTaxed(oPartner.isTaxed);
      setFather(oPartner.father);
      setCreditBalance(oPartner.creditBalance || "0");
      setCreditBalanceUsd(oPartner.creditBalanceUsd || "0");
      setDueBalance(oPartner.dueBalance || "0");
      setDueBalanceUsd(oPartner.dueBalanceUsd || "0");
      setLocation(aLocations.find(oItem => oItem.id === oPartner.idLocation) || null);
      setPrice(aPrices.find(oItem => oItem.id === oPartner.idPriceList) || null);
      setDocumentTypePartner(aDocumentTypesPartners.find(oItem => oItem.id === oPartner.idDocumentType) || null);
      setDiscount(oPartner.discount ?? "0");
      setGroup(aGroups.find(oItem => oItem.id === oPartner.idGroup) || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oPartner]);

  useEffect(() => {
    if (oPartner) {
      if (aLocations?.length > 0 && !oLocation)
        setLocation(aLocations.find(oItem => oItem.id === oPartner.idLocation) || null);
      if (aPrices?.length > 0 && !oPrice)
        setPrice(aPrices.find(oItem => oItem.id === oPartner.idPriceList) || null);
      if (aDocumentTypesPartners?.length > 0 && !oDocumentTypePartner)
        setDocumentTypePartner(aDocumentTypesPartners.find(oItem => oItem.id === oPartner.idDocumentType) || null);
      if (aGroups?.length > 0 && !oGroup)
        setDocumentTypePartner(aDocumentTypesPartners.find(oItem => oItem.id === oPartner.idDocumentType) || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aPrices, aDocumentTypesPartners, aLocations]);

  useEffect(() => {
    if (sReport) {
      printJS({
        base64: true,
        printable: sReport,
        modalMessage: "Cargando...",
        documentTitle: oPartner.name,
        onPrintDialogClose: () => {
          oDispatch(actionPartnersCleanReport());
        },
      });
    }
  }, [sReport]);

  const onSubmit = () => {
    const oData = {
      id: oPartner.id,
      code: sCode.trim(),
      name: sName.trim(),
      businessName: sBusinessName.trim(),
      rut: sRut.trim(),
      email: sEmail.trim(),
      phone1: sPhone1.trim(),
      phone2: sPhone2.trim(),
      document: sDocument.trim(),
      idDocumentType: oDocumentTypePartner?.id || null,
      nameDocumentType: oDocumentTypePartner?.name || null,
      url: sUrl.trim(),
      address: sAddress.trim(),
      zip: sZip.trim(),
      observations: sObservations.trim(),
      isClient: bIsClient,
      isProvider: bIsProvider,
      isTaxed: bIsTaxed,
      idLocation: oLocation?.id || null,
      idPriceList: oPrice?.id || null,
      idFather: oFather?.id || null,
      creditBalance: +sCreditBalance,
      creditBalanceUsd: +sCreditBalanceUsd,
      dueBalance: +sDueBalance,
      dueBalanceUsd: +sDueBalanceUsd,
      isActive: bIsActive,
      discount: +sDiscount ?? 0,
      idGroup: oGroup?.id || null,
    };
    if (!oData.idDocumentType) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired(PartnersLang.Fields.documentType) }));
    if (!oData.document) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired(PartnersLang.Fields.document) }));
    if (!oData.idGroup) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired("Grupo") }));
    if (!oData.idLocation) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired(PartnersLang.Fields.location) }));
    if (!oData.idPriceList) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired(PartnersLang.Fields.priceList) }));
    if (Number.isNaN(oData.discount) || oData.discount > 100 || oData.discount < 0) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldInvalid("Descuento global") }));
    if (!oData.isClient && !oData.isProvider) return oDispatch(actionMessage({ message: PartnersLang.Validations.typeRequired }));
    if (
      Number.isNaN(oData.creditBalance) ||
      Number.isNaN(oData.creditBalanceUsd) ||
      Number.isNaN(oData.dueBalance) ||
      Number.isNaN(oData.dueBalanceUsd)
    )
      return oDispatch(actionMessage({ message: "Corrija los saldos iniciales" }));

    oDispatch(actionPartnersUpdate(oData));
  };

  if (!oPartner) return null;

  const onDelete = (bConfirm = false) => {
    if (!bConfirm) return setDelete(true);
    oDispatch(actionPartnersDelete(oPartner.id));
  };

  const onGetCurrentAccount = (nType) => {
    const oData = {
      idPartner: oPartner.id,
      to: CastDate(new Date(), DATES_FORMATS.MYSQL_SERVER),
      includeBranches: bIsIncludeBranchesDebtor,
    };
    if (nType === PARTNERS_BALANCE_TYPES.CREDITOR) {
      oDispatch(actionPartnersGetCreditorData({ ...oData, idCurrency: CURRENCY_CODES.URUGUAYAN_PESO }));
      return oDispatch(actionPartnersGetCreditorData({ ...oData, idCurrency: CURRENCY_CODES.DOLLAR }));
    }
    oDispatch(actionPartnersGetDebtorData({ ...oData, idCurrency: CURRENCY_CODES.URUGUAYAN_PESO }));
    return oDispatch(actionPartnersGetDebtorData({ ...oData, idCurrency: CURRENCY_CODES.DOLLAR }));
  };

  const onGetStatementAccount = (nType) => {
    setStatementType(nType);
    const oData = {
      getReport: 0,
      idPartner: oPartner.id,
      from: CastDate(SubtractDate(new Date(), 90), DATES_FORMATS.MYSQL_SERVER),
      includeBranches: bIsIncludeBranchesCreditor,
      type: nType,
      send: false,
    };
    oDispatch(actionPartnersGetStatementAccount({ ...oData, idCurrency: CURRENCY_CODES.URUGUAYAN_PESO }));
    return oDispatch(actionPartnersGetStatementAccount({ ...oData, idCurrency: CURRENCY_CODES.DOLLAR }));
  };

  const onGetStatementAccountReport = () => {
    const oData = {
      getReport: 1,
      type: nStatementType,
      idPartner: oPartner.id,
      includeBranches: bIsIncludeBranchesCreditor,
      from: CastDate(SubtractDate(new Date(), 90), DATES_FORMATS.MYSQL_SERVER),
      send: false,
    };
    if (nStatementCurrency === CURRENCY_CODES.URUGUAYAN_PESO)
      return oDispatch(actionPartnersGetStatementAccountReport({ ...oData, idCurrency: CURRENCY_CODES.URUGUAYAN_PESO }));
    return oDispatch(actionPartnersGetStatementAccountReport({ ...oData, idCurrency: CURRENCY_CODES.DOLLAR }));
  };

  const onSendStatementAccountReport = () => {
    if (!bSend)
      return setSend(true);
    setSend(false);
    const oData = {
      getReport: 2,
      type: nStatementType,
      idPartner: oPartner.id,
      includeBranches: bIsIncludeBranchesCreditor,
      from: CastDate(SubtractDate(new Date(), 90), DATES_FORMATS.MYSQL_SERVER),
      idCurrency: nStatementCurrency === CURRENCY_CODES.URUGUAYAN_PESO ? CURRENCY_CODES.URUGUAYAN_PESO : CURRENCY_CODES.DOLLAR,
    };
    return oDispatch(actionPartnersSendStatementAccountReport(oData));
  };

  const onCloseStatementAccountDetails = () => {
    oDispatch(actionPartnersCleanStatementAccount());
  };

  return (
    <ScreenContainer className="pb-5">
      <section className="grid grid-cols-1 md:grid-cols-2">
        <Text bold size="text-3xl" color="text-gray-800 dark:text-gray-200" className="text-center md:text-left md:self-center">
          {PartnersLang.Labels.editPartner}
        </Text>
        <div className="m-auto mt-5 md:m-0 md:ml-auto md:mt-0 flex gap-3">
          <Button
            className="h-full"
            size={BUTTON_CONFIG.SIZE.SM}
            onClick={() => onDelete(false)}
            type={BUTTON_CONFIG.CLASS.DANGER}
            icon={<FaTrashAlt size={16} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} />}
          />
          <Button
            label={PartnersLang.Buttons.save}
            onClick={onSubmit}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </section>
      <section className="px-5 py-3 rounded-lg mt-5 bg-white dark:bg-gray-800">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-5">
          <Input
            value={sCode}
            onChange={setCode}
            className="w-full"
            label={PartnersLang.Fields.code}
            placeholder={PartnersLang.Fields.emptyCode}
          />
          <Input
            disabled
            className="w-full"
            value={oPartner.isActive ? "Activo" : "Inactivo"}
            label={PartnersLang.Fields.status}
            placeholder={PartnersLang.Fields.status}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <Input
              label={PartnersLang.Fields.name}
              placeholder={PartnersLang.Fields.name}
              value={sName}
              onChange={setName}
              className="w-full"
            />
            <Input
              label={PartnersLang.Fields.businessName}
              placeholder={PartnersLang.Fields.businessName}
              value={sBusinessName}
              onChange={setBusinessName}
              className="w-full"
            />
          </div>
          <PartnerSuggestions
            className="w-full"
            onSelect={setFather}
            value={oFather?.name}
            label={PartnersLang.Fields.centralHouse}
            placeholder={PartnersLang.Fields.centralHouse}
          />
          <Input
            label={PartnersLang.Fields.rut}
            placeholder={PartnersLang.Fields.rut}
            value={sRut}
            className="w-full"
            onChange={setRut}
          />
          <div className="grid grid-cols-2 gap-5">
            <ComboBox
              options={aDocumentTypesPartners || []}
              selected={oDocumentTypePartner}
              onChange={setDocumentTypePartner}
              label={PartnersLang.Fields.documentType}
              emptyLabel={PartnersLang.Fields.documentType}
            />
            <Input
              label={PartnersLang.Fields.document}
              placeholder={PartnersLang.Fields.document}
              value={sDocument}
              onChange={setDocument}
              className="w-full"
            />
          </div>
          <Input
            label={PartnersLang.Fields.email}
            placeholder={PartnersLang.Fields.email}
            value={sEmail}
            onChange={setEmail}
            className="w-full"
          />
          <ComboBox
            selected={oGroup}
            onChange={setGroup}
            label="Grupo"
            emptyLabel="Grupo"
            options={groups}
          />
          <div className="grid grid-cols-2 gap-5">
            <Input
              label={PartnersLang.Fields.phone1}
              placeholder={PartnersLang.Fields.phone1}
              value={sPhone1}
              onChange={setPhone1}
              className="w-full"
            />
            <Input
              label={PartnersLang.Fields.phone2}
              placeholder={PartnersLang.Fields.phone2}
              value={sPhone2}
              onChange={setPhone2}
              className="w-full"
            />
          </div>
          <Input
            label={PartnersLang.Fields.url}
            placeholder={PartnersLang.Fields.url}
            value={sUrl}
            onChange={setUrl}
            className="w-full"
          />
          <ComboBox
            options={aLocations}
            selected={oLocation}
            onChange={setLocation}
            label={PartnersLang.Fields.location}
            emptyLabel={PartnersLang.Fields.location}
          />
          <Input
            value={sAddress}
            className="w-full"
            onChange={setAddress}
            label={PartnersLang.Fields.address}
            placeholder={PartnersLang.Fields.address}
          />
          <Input
            value={sZip}
            className="w-full"
            onChange={setZip}
            label={PartnersLang.Fields.zip}
            placeholder={PartnersLang.Fields.zip}
          />
          <div className="flex flex-row my-3">
            <Checkbox
              checked={bIsClient}
              containerClassName="flex-1 pt-8"
              label={PartnersLang.Fields.isClient}
              onChange={() => setIsClient(bValue => !bValue)}
            />
            <Checkbox
              checked={bIsProvider}
              containerClassName="flex-1 pt-8"
              label={PartnersLang.Fields.isProvider}
              onChange={() => setIsProvider(bValue => !bValue)}
            />
          </div>
          <div className="grid grid-cols-2 gap-5">
            <ComboBox
              options={aPrices}
              selected={oPrice}
              onChange={setPrice}
              label={PartnersLang.Fields.priceList}
              emptyLabel={PartnersLang.Fields.priceList}
            />
            <Input
              value={sDiscount}
              onChange={setDiscount}
              label="Descuento global"
              className="w-full text-right"
              placeholder="Descuento global"
            />
          </div>
          <div className="flex flex-row my-3">
            <Checkbox
              checked={bIsTaxed}
              containerClassName="flex-1 pt-8"
              label={PartnersLang.Fields.isTaxed}
              onChange={() => setIsTaxed(bValue => !bValue)}
            />
            <Checkbox
              checked={bIsActive}
              containerClassName="flex-1 pt-8"
              label="Es activo"
              onChange={() => setIsActive(bValue => !bValue)}
            />
          </div>
          <Input
            containerClassName="md:col-span-2"
            label={PartnersLang.Fields.observations}
            placeholder={PartnersLang.Fields.observations}
            value={sObservations}
            onChange={setObservations}
            className="w-full"
            textarea
          />
        </div>
      </section>
      <section className="px-5 py-3 rounded-lg mt-5 bg-white dark:bg-gray-800">
        <Text bold size="text-lg" className="mt-3">{PartnersLang.Labels.currentAccount}</Text>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-5">
          <div className="border-gray-600 dark:border-gray-50 border-2 rounded-md p-3">
            <Text>{PartnersLang.Labels.debtor}</Text>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-5 text-center">
              <Button
                type={BUTTON_CONFIG.CLASS.OUTLINE}
                label={PartnersLang.Labels.currentBalance}
                onClick={() => onGetCurrentAccount(PARTNERS_BALANCE_TYPES.DEBTOR)}
              />
              <Button
                type={BUTTON_CONFIG.CLASS.OUTLINE}
                label={PartnersLang.Labels.statementAccount}
                onClick={() => onGetStatementAccount(PARTNERS_BALANCE_TYPES.DEBTOR)}
              />
            </div>
            <Checkbox
              checked={bIsIncludeBranchesDebtor}
              containerClassName="flex justify-center"
              label={PartnersLang.Labels.includeBranches}
              onChange={() => setIsIncludeBranchesDebtor(bValue => !bValue)}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-5">
              <Input
                disabled
                className="w-full text-right"
                value={oDebtorData?.uyu ?? "0"}
                label={`${PartnersLang.Labels.debtor} UYU`}
              />
              <Input
                disabled
                className="w-full text-right"
                value={oDebtorData?.dollar ?? "0"}
                label={`${PartnersLang.Labels.debtor} USD`}
              />
            </div>
          </div>
          <div className="border-gray-600 dark:border-gray-50 border-2 rounded-md p-3">
            <Text>{PartnersLang.Labels.creditor}</Text>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-5 text-center">
              <Button
                type={BUTTON_CONFIG.CLASS.OUTLINE}
                label={PartnersLang.Labels.currentBalance}
                onClick={() => onGetCurrentAccount(PARTNERS_BALANCE_TYPES.CREDITOR)}
              />
              <Button
                type={BUTTON_CONFIG.CLASS.OUTLINE}
                label={PartnersLang.Labels.statementAccount}
                onClick={() => onGetStatementAccount(PARTNERS_BALANCE_TYPES.CREDITOR)}
              />
            </div>
            <Checkbox
              checked={bIsIncludeBranchesCreditor}
              containerClassName="flex justify-center"
              label={PartnersLang.Labels.includeBranches}
              onChange={() => setIsIncludeBranchesCreditor(bValue => !bValue)}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-5">
              <Input
                disabled
                className="w-full text-right"
                value={oCreditorData?.uyu ?? "0"}
                label={`${PartnersLang.Labels.creditor} UYU`}
              />
              <Input
                disabled
                className="w-full text-right"
                value={oCreditorData?.dollar ?? "0"}
                label={`${PartnersLang.Labels.creditor} USD`}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="px-5 py-3 rounded-lg mt-5 bg-white dark:bg-gray-800">
        <Text bold size="text-lg" className="mt-3">{PartnersLang.Labels.openingBalances}</Text>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-5">
          <Input
            value={sCreditBalance}
            onChange={setCreditBalance}
            className="w-full text-right"
            label={PartnersLang.Fields.creditBalance}
            placeholder={PartnersLang.Fields.emptyCredit}
          />
          <Input
            value={sCreditBalanceUsd}
            className="w-full text-right"
            onChange={setCreditBalanceUsd}
            label={PartnersLang.Fields.creditBalanceUsd}
            placeholder={PartnersLang.Fields.emptyCredit}
          />
          <Input
            value={sDueBalance}
            onChange={setDueBalance}
            className="w-full text-right"
            label={PartnersLang.Fields.dueBalance}
            placeholder={PartnersLang.Fields.emptyDue}
          />
          <Input
            value={sDueBalanceUsd}
            onChange={setDueBalanceUsd}
            className="w-full text-right"
            label={PartnersLang.Fields.dueBalanceUsd}
            placeholder={PartnersLang.Fields.emptyDue}
          />
        </div>
      </section>
      <section className="mt-3 px-3 grid grid-cols-2">
        <div>
          <Text size="text-sm">{CastDate(oPartner.createdAt)}</Text>
        </div>
        <div className="text-right">
          <Text size="text-sm"># {oPartner.id}</Text>
        </div>
      </section>
      <ConfirmationModal
        show={bDelete}
        onClose={() => setDelete(false)}
        onConfirm={() => onDelete(true)}
        title="Eliminar socio"
        question={`Está seguro que desea eliminar el socio "${oPartner?.name}"`}
        message="Esta acción no se puede revertir"
      />
      <ConfirmationModal
        show={bSend}
        onClose={() => setSend(false)}
        title="Envíar reporte por email"
        message="Esta acción no se puede revertir"
        onConfirm={() => onSendStatementAccountReport(true)}
        question="Está seguro que desea envíar el reporte al socio"
      />
      <CustomModal
        className="md:max-w-4xl"
        show={!!oDataStatementAccount}
        onClose={onCloseStatementAccountDetails}
        title={`${PartnersLang.Labels.statementAccount} (${nStatementType === PARTNERS_BALANCE_TYPES.DEBTOR ? "Deudor" : "Acreedor"})`}
      >
        <div className="grid grid-cols-2 text-center m-auto my-2">
          <Button
            label="UYU"
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
            active={nStatementCurrency === CURRENCY_CODES.URUGUAYAN_PESO}
            onClick={() => setStatementCurrency(CURRENCY_CODES.URUGUAYAN_PESO)}
          />
          <Button
            label="USD"
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
            active={nStatementCurrency === CURRENCY_CODES.DOLLAR}
            onClick={() => setStatementCurrency(CURRENCY_CODES.DOLLAR)}
          />
        </div>
        <div className="flex gap-3 mb-3 justify-end">
          <Button
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
            icon={<FiDownload className="text-lg" />}
            onClick={onGetStatementAccountReport}
          />
          <Button
            size={BUTTON_CONFIG.SIZE.SM}
            type={BUTTON_CONFIG.CLASS.OUTLINE}
            icon={<BiEnvelope className="text-lg" />}
            onClick={onSendStatementAccountReport}
          />
        </div>
        <Table>
          <Table.Header>
            <Table.Row isHeaderOrFooter>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                {PartnersLang.Fields.date}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center">
                {PartnersLang.Fields.documentType}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-center hidden md:table-cell">
                {PartnersLang.Fields.document}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                {PartnersLang.Labels.amount}
              </Table.Col>
              <Table.Col scope="col" className="px-6 py-3 text-right hidden md:table-cell">
                Parcial
              </Table.Col>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getStatementData?.length > 0 ? (
              getStatementData?.map((oItem, nIndex) => (
                <Table.Row key={oItem.id}>
                  <Table.Col
                    scope="row"
                    className="text-xs md:text-sm px-2 md:px-6 py-4 font-medium text-gray-800 dark:text-white whitespace-nowrap text-center"
                  >
                    {oItem.date ? CastDate(oItem.date, DATES_FORMATS.DEFAULT) : "-"}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center">
                    {oItem.type}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-center hidden md:table-cell">
                    {oItem.identifier ?? "-"}
                  </Table.Col>
                  <Table.Col className="px-6 py-4 text-right hidden md:table-cell">
                    {oItem.amount}
                  </Table.Col>
                  <Table.Col className={`px-6 py-4 text-right hidden md:table-cell ${nIndex === oDataStatementAccount?.uyu?.length - 1 && "font-bold text-red-600"}`}>
                    {oItem.partial}
                  </Table.Col>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Col className="px-6 py-4 text-center" colSpan={5}>
                  <em>No se encontraron resultados</em>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </CustomModal>
    </ScreenContainer>
  );
};

export default PartnersUpdate;
