import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaSave } from "react-icons/fa";

import { Button, Checkbox, ComboBox, Input, PartnerSuggestions, ScreenContainer, Text } from "components";

import { actionMessage } from "redux/shared/SharedSlice";
import { actionPricesGetAll, selectAllPrices } from "pages/prices";
import { actionLocationsGetAll, selectAllLocations } from "pages/locations";
import { actionPartnersClean, actionPartnersSave, selectStatusPartners } from "./redux";
import { actionDocumentTypesPartnersGetAll, selectDocumentTypesPartners } from "pages/documentTypesPartners";

import { BUTTON_CONFIG } from "config/constants/Button";
import { CONSTANTS_COLORS } from "config/constants/Colors";
import PartnersLang from "./lang";

import { actionPartnersGroupGetAll, selectPartnersGroupState } from "./groups";

const PartnersAdd = () => {
  const oDispatch = useDispatch();
  const onNavigate = useNavigate();

  const aPrices = useSelector(selectAllPrices);
  const bStatus = useSelector(selectStatusPartners);
  const aLocations = useSelector(selectAllLocations);
  const aDocumentTypesPartners = useSelector(selectDocumentTypesPartners);
  const { aGroups } = useSelector(selectPartnersGroupState);

  const [sCode, setCode] = useState("");
  const [sName, setName] = useState("");
  const [sBusinessName, setBusinessName] = useState("");
  const [sRut, setRut] = useState("");
  const [sEmail, setEmail] = useState("");
  const [sPhone1, setPhone1] = useState("");
  const [sPhone2, setPhone2] = useState("");
  const [sDocument, setDocument] = useState("");
  const [sUrl, setUrl] = useState("");
  const [sAddress, setAddress] = useState("");
  const [sZip, setZip] = useState("");
  const [sObservations, setObservations] = useState("");
  const [sDiscount, setDiscount] = useState("0");
  const [oGroup, setGroup] = useState(null);
  const [bIsClient, setIsClient] = useState(true);
  const [bIsProvider, setIsProvider] = useState(false);
  const [bIsTaxed, setIsTaxed] = useState(true);

  const [oFather, setFather] = useState(null);
  const [oLocation, setLocation] = useState(null);
  const [oDocumentTypePartner, setDocumentTypePartner] = useState(null);
  const [oPrice, setPrice] = useState(null);

  useEffect(() => {
    if (!aLocations || aLocations.length === 0) oDispatch(actionLocationsGetAll());
    if (!aPrices || aPrices.length === 0) oDispatch(actionPricesGetAll());
    if (!aDocumentTypesPartners || aDocumentTypesPartners.length === 0) oDispatch(actionDocumentTypesPartnersGetAll());
    if (!aGroups || aGroups.length === 0) oDispatch(actionPartnersGroupGetAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bStatus) {
      onNavigate("/partners");
      oDispatch(actionPartnersClean());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bStatus]);

  useEffect(() => {
    if (aPrices) setPrice(aPrices.find(item => item.id === 1) || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aPrices]);

  const onSubmit = () => {
    const oData = {
      code: sCode.trim(),
      name: sName.trim(),
      businessName: sBusinessName.trim(),
      rut: sRut.trim(),
      email: sEmail.trim(),
      phone1: sPhone1.trim(),
      phone2: sPhone2.trim(),
      document: sDocument.trim(),
      idDocumentType: oDocumentTypePartner?.id || null,
      nameDocumentType: oDocumentTypePartner?.name || null,
      url: sUrl.trim(),
      address: sAddress.trim(),
      zip: sZip.trim(),
      observations: sObservations.trim(),
      isClient: bIsClient,
      isProvider: bIsProvider,
      isTaxed: bIsTaxed,
      idLocation: oLocation?.id || null,
      idPriceList: oPrice?.id || null,
      idFather: oFather?.id || null,
      discount: +sDiscount ?? 0,
      idGroup: oGroup?.id || null,
    };
    if (!oData.idDocumentType) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired(PartnersLang.Fields.documentType) }));
    if (!oData.document) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired(PartnersLang.Fields.document) }));
    if (!oData.idGroup) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired("Grupo") }));
    if (!oData.idLocation) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired(PartnersLang.Fields.location) }));
    if (!oData.idPriceList) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldRequired(PartnersLang.Fields.priceList) }));
    if (!oData.isClient && !oData.isProvider) return oDispatch(actionMessage({ message: PartnersLang.Validations.typeRequired }));
    if (Number.isNaN(oData.discount) || oData.discount > 100 || oData.discount < 0) return oDispatch(actionMessage({ message: PartnersLang.Validations.fieldInvalid("Descuento global") }));
    oDispatch(actionPartnersSave(oData));
  };

  const groups = useMemo(() => {
    if (!aGroups) return [];
    let aGroupsCopy = JSON.parse(JSON.stringify(aGroups));
    return aGroupsCopy?.sort((itemA, itemB) => {
      let nameA = itemA.name.toUpperCase();
      let nameB = itemB.name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }) || [];
  }, [aGroups]);

  return (
    <ScreenContainer className="pb-5">
      <section className="grid grid-cols-1 md:grid-cols-2">
        <Text bold size="text-3xl" color="text-gray-800 dark:text-gray-200" className="text-center md:text-left md:self-center">
          {PartnersLang.Labels.createPartner}
        </Text>
        <div className="m-auto mt-5 md:m-0 md:ml-auto md:mt-0">
          <Button
            label={PartnersLang.Buttons.save}
            onClick={onSubmit}
            type={BUTTON_CONFIG.CLASS.SUCCESS}
            icon={<FaSave size={20} color={CONSTANTS_COLORS.COLORS.WHITE_COLOR} className="ml-2" />}
          />
        </div>
      </section>
      <section className="px-5 py-3 rounded-lg mt-5 bg-white dark:bg-gray-800">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-5">
          <Input
            label={PartnersLang.Fields.code}
            placeholder={PartnersLang.Fields.code}
            value={sCode}
            onChange={setCode}
            className="w-full"
          />
          <PartnerSuggestions
            className="w-full"
            onSelect={setFather}
            label={PartnersLang.Fields.centralHouse}
            placeholder={PartnersLang.Fields.centralHouse}
          />
          <Input
            value={sName}
            onChange={setName}
            className="w-full"
            label={PartnersLang.Fields.name}
            placeholder={PartnersLang.Fields.name}
          />
          <Input
            label={PartnersLang.Fields.businessName}
            placeholder={PartnersLang.Fields.businessName}
            value={sBusinessName}
            onChange={setBusinessName}
            className="w-full"
          />
          <Input
            label={PartnersLang.Fields.rut}
            placeholder={PartnersLang.Fields.rut}
            value={sRut}
            className="w-full"
            onChange={setRut}
          />
          <div className="grid grid-cols-2 gap-5">
            <ComboBox
              options={aDocumentTypesPartners || []}
              selected={oDocumentTypePartner}
              onChange={setDocumentTypePartner}
              label={PartnersLang.Fields.documentType}
              emptyLabel={PartnersLang.Fields.documentType}
            />
            <Input
              label={PartnersLang.Fields.document}
              placeholder={PartnersLang.Fields.document}
              value={sDocument}
              onChange={setDocument}
              className="w-full"
            />
          </div>
          <Input
            label={PartnersLang.Fields.email}
            placeholder={PartnersLang.Fields.email}
            value={sEmail}
            onChange={setEmail}
            className="w-full"
          />
          <ComboBox
            selected={oGroup}
            onChange={setGroup}
            label="Grupo"
            emptyLabel="Grupo"
            options={groups}
          />
          <div className="grid grid-cols-2 gap-5">
            <Input
              label={PartnersLang.Fields.phone1}
              placeholder={PartnersLang.Fields.phone1}
              value={sPhone1}
              onChange={setPhone1}
              className="w-full"
            />
            <Input
              label={PartnersLang.Fields.phone2}
              placeholder={PartnersLang.Fields.phone2}
              value={sPhone2}
              onChange={setPhone2}
              className="w-full"
            />
          </div>
          <ComboBox
            options={aLocations}
            selected={oLocation}
            onChange={setLocation}
            label={PartnersLang.Fields.location}
            emptyLabel={PartnersLang.Fields.location}
          />
          <Input
            value={sAddress}
            className="w-full"
            onChange={setAddress}
            label={PartnersLang.Fields.address}
            placeholder={PartnersLang.Fields.address}
          />
          <Input
            value={sZip}
            className="w-full"
            onChange={setZip}
            label={PartnersLang.Fields.zip}
            placeholder={PartnersLang.Fields.zip}
          />
          <Input
            label={PartnersLang.Fields.url}
            placeholder={PartnersLang.Fields.url}
            value={sUrl}
            onChange={setUrl}
            className="w-full"
          />
          <div className="grid grid-cols-2 gap-5">
            <ComboBox
              options={aPrices}
              selected={oPrice}
              onChange={setPrice}
              label={PartnersLang.Fields.priceList}
              emptyLabel={PartnersLang.Fields.priceList}
            />
            <Input
              value={sDiscount}
              onChange={setDiscount}
              label="Descuento global"
              className="w-full text-right"
              placeholder="Descuento global"
            />
          </div>
          <div className="flex flex-row md:col-span-2 my-2">
            <Checkbox
              checked={bIsClient}
              containerClassName="flex-1"
              label={PartnersLang.Fields.isClient}
              onChange={() => setIsClient(bValue => !bValue)}
            />
            <Checkbox
              checked={bIsProvider}
              containerClassName="flex-1"
              label={PartnersLang.Fields.isProvider}
              onChange={() => setIsProvider(bValue => !bValue)}
            />
            <Checkbox
              checked={bIsTaxed}
              containerClassName="flex-1"
              label={PartnersLang.Fields.isTaxed}
              onChange={() => setIsTaxed(bValue => !bValue)}
            />
          </div>
          <Input
            containerClassName="md:col-span-2"
            label={PartnersLang.Fields.observations}
            placeholder={PartnersLang.Fields.observations}
            value={sObservations}
            onChange={setObservations}
            className="w-full"
            textarea
          />
        </div>
      </section>
    </ScreenContainer>
  );
};

export default PartnersAdd;
